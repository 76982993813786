export default [
  {
    header: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: 'Story',
        route: 'home',
        icon: 'LoaderIcon'
      },
      {
        title: 'Chat',
        route: 'chat',
        icon: 'MessageSquareIcon'
      }
    ]
  }
]
